<template>
    <div class="inner_pages" id="playbook_index">
        <div class="respective_content">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Playbooks</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortPlaybook = !sortPlaybook" v-tooltip="`Sort Playbooks Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'playbooks')" @click="importPlaybook = !importPlaybook" v-tooltip="`Import a shared playbook`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li @click="smartCategories = !smartCategories" v-tooltip="`Manage Smart Categories`" position="bottom"><img src="@/assets/images/smart.svg"></li>
                        <li class="add_btn light" @click="playbookType = true" v-if="companyUserCan('create', 'playbooks')"><i class="fas fa-plus-circle"></i>Create A Playbook</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li v-show="selectedPlaybook.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedPlaybook.length ? `${selectedPlaybook.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedPlaybook.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'playbooks')"  @click="handleDuplicatePlaybook(selectedPlaybook)">Duplicate</li>
                                    <li v-if="companyUserCan('delete', 'playbooks')" class="danger" @click="handleDeletePlaybook(selectedPlaybook)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the playbooks feature</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList" v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) of filters" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)" :key="f">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="playbook-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-show="playbookLoader"><quote-loader /></div>
                <div class="action_content" v-show="!playbookLoader">
                    <asset-folder v-model="assetFolder" title="Playbook" asset-type="playbooks" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :update-breadcrumb="updateFolderBreadcrumb" :section-breadcrumb="folderBreadcrumb['playbooks']"/>
                    <ul class="playbook_list" v-if="!isArchived || folderParams.parent_id > 0">
                        <li v-for="(playbook, p) of playbooks.data" :key="p">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedPlaybook.includes(playbook.id) }">
                                    <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" class="img">
                                    <img v-else src="@/assets/images/thumb/default-playbook.svg" class="img">
                                    <span v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact" class="replace_img" @click="editThumbnail = true; activePlaybook = playbook;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <div class="switch_list">
                                        <label :for="`pin-${playbook.id}`" v-if="!playbook.is_shared_with_contact" class="switch_option capsule_btn border-0 py-1 m-0" @click="handlePin($event, playbook)">
                                            <h5 class="mr-1">Pin</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" :checked="playbook.is_pinned == 1" :id="`pin-${playbook.id}`" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label :for="`all-clients-${playbook.id}`" v-if="!playbook.is_shared_with_contact" class="switch_option capsule_btn border-0 py-1 m-0" @click="handleToggleAllClients($event, playbook)">
                                            <h5 class="mr-1">All Clients</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" :checked="playbook.all_clients == 1" :id="`all-clients-${playbook.id}`" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label :for="`client_tease-${playbook.id}`" class="switch_option capsule_btn border-0 py-1 m-0" @click="handleTeaseConfirmation($event, playbook)">
                                            <h5 class="mr-1">Tease</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" v-model="playbook.tease_enabled" :id="`client_tease-${playbook.id}`" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`playbook-${playbook.id}`" class="checkbox" v-if="!playbook.is_shared_with_contact">
                                                <input type="checkbox" :id="`playbook-${playbook.id}`" :value="playbook.id" v-model="selectedPlaybook" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <template v-if="playbook.analytics_stats">
                                            <li>0% Opt-in rate</li>
                                            <li>{{ playbook.analytics_stats.members }} Members</li>
                                            <li>{{ playbook.analytics_stats.completed ? Math.round(playbook.analytics_stats.completed * 100 / playbook.analytics_stats.members) : 0 }}% Completion Rate</li>
                                            <li>{{ Math.round(playbook.analytics_stats.started_rate) }}% Engagement</li>
                                            <li>{{ playbook.analytics_stats.questions }} Questions</li>
                                            <li>{{ playbook.analytics_stats.non_started }} Non-starters</li>
                                            <li v-if="playbook.tease_enabled && playbook.tease_setting && playbook.tease_setting.setting_value">{{ `This asset is ${playbook.tease_setting.setting_value.is_premium ? 'paid' : 'free'}` }}</li>
                                        </template>
                                        <template v-else>
                                            <li>0% Opt-in rate</li>
                                            <li>{{ playbook.user_count }} Members</li>
                                            <li>{{ playbook.completion_rate }}% Completion Rate</li>
                                            <li>{{ playbook.engagement }}% Engagement</li>
                                            <li>{{ playbook.questions }} Questions</li>
                                            <li>{{ playbook.non_starter }} Non-starters</li>
                                            <li v-if="playbook.tease_enabled && playbook.tease_setting && playbook.tease_setting.setting_value">{{ `This asset is ${playbook.tease_setting.setting_value.is_premium ? 'paid' : 'free'}` }}</li>
                                        </template>
                                    </ul>
                                </div>
                                <div v-tooltip="playbook.title">
                                    <router-link v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact" :to="{ name: 'PlaybookEdit', params: { playbook: playbook.id }}" class="item_ttl">
                                        {{ playbook.title }}
                                    </router-link>
                                    <a class="item_ttl" v-else>{{ playbook.title }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="member pointer" @click="playbookUsers = true; activePlaybook = playbook;" v-tooltip="`Clients Assigned to this Playbook`">
                                        <img src="@/assets/images/member.svg">
                                        {{ playbook.user_count }} Members
                                    </div>
                                    <ul>
                                        <li @click="analytics = true;  activePlaybook = playbook;"><span v-tooltip="`Open Analytics`"><i class="fas fa-chart-line"></i></span></li>
                                        <li @click="playbookLink = true;  activePlaybook = playbook;"><span v-tooltip="`Live Playbook Link (Must be assigned)`"><i class="fas fa-link"></i></span></li>
                                        <li @click="moreOption(playbook.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  playbook.id}">
                                                <li v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact">
                                                    <router-link :to="{ name: 'PlaybookEdit', params: { playbook: playbook.id }}">
                                                        Edit Playbook
                                                    </router-link>
                                                </li>
                                                <li @click="editThumbnail = true; activePlaybook = playbook;" v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact">Edit Thumbnail Image</li>
                                                <li @click="renamePlaybook = true; activePlaybook = playbook;" v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact">Rename Playbook</li>
                                                <li @click="analytics = true;  activePlaybook = playbook;">Show Analytics</li>
                                                <li @click="assignContact = true;  activePlaybook = playbook;" v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact">Assign Contacts</li>
                                                <li @click="playbookLink = true;  activePlaybook = playbook;" v-if="!playbook.is_shared_with_contact">Playbook Link</li>
                                                <li @click="handleSharePlaybook(playbook)" v-if="!playbook.is_shared_with_contact">Share Playbook</li>
                                                <li @click="movePlaybook = true; activePlaybook = playbook;" v-if="!playbook.is_shared_with_contact">Move to Folder</li>
                                                <li @click="shareLocation = true; activePlaybook = playbook;" v-if="user.is_organization_owner || user.is_company_location == 1">Share to Location</li>
                                                <li @click="handleDuplicatePlaybook([playbook.id]);" v-if="companyUserCan('create', 'playbooks') && !playbook.is_shared_with_contact">Duplicate</li>
                                                <li @click="editDeliveryMessage = true; activePlaybook = playbook;" v-if="companyUserCan('update', 'playbooks') && !playbook.is_shared_with_contact">Edit Delivery Message</li>
                                                <li @click="activePlaybook = playbook; showOptin = true;">Opt-ins <span class="tag">New!</span></li>
                                                <li @click="handleDeletePlaybook([playbook.id]);" v-if="companyUserCan('delete', 'playbooks') && !playbook.is_shared_with_contact" class="danger" >Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_box" v-if="!playbooks.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Playbooks yet. Click the play button to get started.</h4>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ playbooks.from ? playbooks.from : 0 }} - {{ playbooks.to ? playbooks.to : 0 }} of {{ playbooks.total ? playbooks.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="playbooks.total && (!isArchived || folderParams.parent_id > 0)">
                    <pagination v-model="page" :pages="playbooks.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>

        <div class="modal secondary add_types" v-if="playbookType">
            <div class="modal_container">
                <h3 class="sub_header mt-1 mb-4"> Select Playbook Type
                    <button class="close_btn" @click="playbookType = false;"><i class="fas fa-times"></i></button>
                </h3>
                <ul>
                    <li @click="createNew('classic')">
                        <div class="card_item">
                            <img src="@/assets/images/classic.svg" alt=""> Classic Playbook
                        </div>
                    </li>
                    <li @click="createNew('canva')">
                        <div class="card_item">
                            <img src="@/assets/images/canva.png" alt=""> Canva Playbook
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/e93a71909e3242f8b16ec7a57397ed21?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>

        <div class="header_filter" :class="{'show' : mobile_filter}">
            <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
            <div class="top_area">
                <h3 class="sub_heading">Actions</h3>
                <ul>
                    <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                    <li @click="sortPlaybook = !sortPlaybook" v-tooltip="`Sort Playbooks Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                    <li v-if="companyUserCan('create', 'playbooks')" @click="importPlaybook = !importPlaybook" v-tooltip="`Import a shared playbook`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    <li @click="smartCategories = !smartCategories" v-tooltip="`Manage Smart Categories`" position="bottom"><img src="@/assets/images/smart.svg"></li>
                </ul>
            </div>
            <div class="action_area">
                <h3 class="sub_heading2">Filters</h3>
                <div class="search_area">
                    <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <ul>
                    <li v-show="selectedPlaybook.length || showContent">
                        <label for="check" class="checkbox">
                            <h5 class="p-0">{{ selectedPlaybook.length ? `${selectedPlaybook.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                        </label>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedPlaybook.length">
                        Bulk Action<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                            <ul>
                                <li v-if="companyUserCan('create', 'playbooks')"  @click="handleDuplicatePlaybook(selectedPlaybook)">Duplicate</li>
                                <li v-if="companyUserCan('delete', 'playbooks')" class="danger" @click="handleDeletePlaybook(selectedPlaybook)">Delete</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2" v-click-outside="closeSortingDropdown2">
                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) of filters" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)" :key="f">
                                    {{ filter.title }}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <playbook-user v-model="playbookUsers" :playbook="activePlaybook" />
        <rename-playbook v-model="renamePlaybook" :playbook="activePlaybook" />
        <analytics-component v-model="analytics" :playbook="activePlaybook" />
        <asset-link v-model="playbookLink" title="Playbook" :selectedAsset="activePlaybook" :has-optin-link="true" />
        <assign-contact v-model="assignContact" :playbook="activePlaybook" />
        <share-component v-model="sharePlaybook" title="Playbook" :selectedAsset="activePlaybook" />
        <move-asset v-model="movePlaybook" title="Playbook" asset-type="playbooks" :selectedAsset="activePlaybook" :refreshAsset="refreshPlaybook" />
        <share-location v-model="shareLocation" title="Playbook" :selectedAsset="activePlaybook" />
        <edit-delivery-message v-model="editDeliveryMessage" :playbook="activePlaybook" />
        <new-playbook v-model="newPlaybook"/>
        <edit-thumbnail-asset v-model="editThumbnail" title="Playbook" :selectedAsset="activePlaybook" :refreshAsset="refreshPlaybook" />
        <sort-asset v-model="sortPlaybook" title="Playbook" asset-type="playbooks" :update-sorting="sortingFilter" :folder-id="params.folder_id" service="playbook" />
        <import-share-link v-model="importPlaybook" title="Playbook" :refreshAsset="refreshPlaybook" :has-saved-styling="true" />
        <smart-categories v-model="smartCategories" />
        <canva-widget v-model="canvaWidget" />
        <optin-component v-model="showOptin" :playbook="activePlaybook" :refresh-playbook="handleRefreshPlaybook" />
        <tease-component v-model="teaseConfirmation" service="playbook" :asset="activePlaybook" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const PlaybookUser = defineAsyncComponent(() => import('@/pages/playbook/components/PlaybookUser'))
    const RenamePlaybook = defineAsyncComponent(() => import('@/pages/playbook/components/RenamePlaybook'))
    const AnalyticsComponent = defineAsyncComponent(() => import('@/pages/playbook/components/Analytics'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const AssignContact = defineAsyncComponent(() => import('@/pages/playbook/components/AssignContact'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const MoveAsset = defineAsyncComponent(() => import('@/components/MoveAsset'))
    const ShareLocation = defineAsyncComponent(() => import('@/components/ShareLocation'))
    const EditDeliveryMessage = defineAsyncComponent(() => import('@/pages/playbook/components/EditDeliveryMessage'))
    const NewPlaybook = defineAsyncComponent(() => import('@/pages/playbook/components/NewPlaybook'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const SmartCategories = defineAsyncComponent(() => import('@/pages/playbook/components/SmartCategories'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const CanvaWidget = defineAsyncComponent(() => import('@/pages/playbook/components/CanvaWidget'))
    const OptinComponent = defineAsyncComponent(() => import('@/pages/playbook/components/Optin'))
    const TeaseComponent = defineAsyncComponent(() => import('@/components/TeaseComponent'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Pagination from '@hennge/vue3-pagination'

    export default {
        name: 'Playbook Dashboard',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                playbookType: false,
                newPlaybook: false,
                canvaWidget: false,
                assetFolder: false,
                actionList: false,
                actionList2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                playbookUsers: false,
                isTyping: false,
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'ordering', order: 'desc'},
                    { title: 'A-Z', field: 'title', order: 'asc'},
                    { title: 'Z-A', field: 'title', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Highest Engagement', field: 'engagement', order: 'desc'},
                    { title: 'Lowest Engagement', field: 'engagement', order: 'asc'},
                    { title: 'Completion Rate', field: 'completion_rate', order: 'asc'},
                    { title: 'Questions Asked', field: 'questions', order: 'desc'},
                    { title: 'Subscriber Count', field: 'members', order: 'desc'},
                    { title: 'Number of Non-starters', field: 'non_starter', order: 'desc'},
                    { title: 'Pinned', field: 'is_pinned', order: 'desc'},
                    { title: 'Teased', field: 'tease_enabled', order: 'desc'},
                ],
                selectedPlaybook: [],
                activePlaybook: {},
                renamePlaybook: false,
                analytics: false,
                playbookLink: false,
                assignContact: false,
                sharePlaybook: false,
                movePlaybook: false,
                shareLocation: false,
                editDeliveryMessage: false,
                editThumbnail: false,
                sortPlaybook: false,
                importPlaybook: false,
                smartCategories: false,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                assetFolderWatcher: 0,
                isMoreOption: false,
                moreOptionDropdown: 0,
                isLastBreadcrumb: false,
                perPageFilter: 6,
                search: '',
                page: 1,
                showContent: false,
                searchField: false,
                isAnalyticRoute: false,
                mobile_filter: false,
                showOptin: false,
                teaseConfirmation: false,
            };
        },

        components: {
            PageFilter,
            Pagination,
            PlaybookUser,
            RenamePlaybook,
            AnalyticsComponent,
            AssetLink,
            AssignContact,
            ShareComponent,
            MoveAsset,
            ShareLocation,
            EditDeliveryMessage,
            NewPlaybook,
            EditThumbnailAsset,
            SortAsset,
            ImportShareLink,
            SmartCategories,
            AssetFolder,
            CanvaWidget,
            OptinComponent,
            TeaseComponent,
        },

        watch: {
            search (val) {
                const vm = this;

                if (!vm.isAnalyticRoute) {
                    vm.setDashboardParams({ key: 'page', value: 1 });
                    vm.setDashboardParams({ key: 'search', value: val });

                    setTimeout(() => {
                        vm.isTyping = false;
                    }, 1500);
                } else {
                    vm.isAnalyticRoute = false;
                }
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getPlaybooks(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                             vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getPlaybooks(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.selectedPlaybook = [];

                vm.getPlaybooks(vm.params);
            },

            assetFolder (val) {
                const vm = this;

                vm.setIsAssetFolder(val);
            },

            canvaWidget (val) {
                const vm = this;

                if (!val) {
                    vm.getPlaybooks(vm.params);
                }
            },

            showOptin (val) {
                const vm = this;

                if (!val) {
                    vm.getPlaybooks(vm.params);
                }
            },

            teaseConfirmation (val) {
                const vm = this;

                if (!val) {
                    vm.getPlaybooks(vm.params);
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                playbooks: state => state.playbookModule.playbooks,
                playbookLoader: state => state.playbookModule.playbookLoader,
                isArchived: state => state.folderModule.isArchived,
                params: state => state.playbookModule.params,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        created () {
            const vm = this;

            const routeParams = Object.keys(vm.$route.query).length && vm.$route.query.action && vm.$route.query.playbook_id ? true : false;

            if (routeParams) {
                vm.resetDashboardParams();
            }
        },

        mounted () {
            const vm = this;

            const routeParams = Object.keys(vm.$route.query).length && vm.$route.query.action && vm.$route.query.playbook_id ? true : false;

            if (routeParams) {
                vm.isAnalyticRoute = true;

                vm.resetDashboardParams();
                vm.setDashboardParams({ key: 'playbook_id', value: vm.$route.query.playbook_id });

                vm.getPlaybooks(vm.params).then((result) => {
                    if (result) {
                        vm.setDashboardParams({ key: 'playbook_id', value: '' });

                        if (vm.playbooks.total) {
                            vm.activePlaybook = vm.playbooks.data[0];
                            vm.analytics      = true;
                            vm.search         = vm.activePlaybook.title;

                            vm.setDashboardParams({ key: 'search', value: vm.search });
                        }
                    }
                });
            } else {
                if (vm.isPreviousFolder) {
                    vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                    vm.folderParams.archived = vm.isArchived ? 1 : 0,
                    vm.isLastBreadcrumb      = true;
                    vm.assetFolder           = vm.isAssetFolder ? true : false;
                } else {
                    vm.folderParams.archived = vm.params.archived ? 1 : 0;
                }

                vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

                vm.setIsPreviousFolder(false);

                if (vm.playbooks.total == 0 || !vm.playbooks.total) {
                    vm.getPlaybooks(vm.params);
                }

                if (vm.params.search) {
                    vm.search = vm.params.search;
                }

                if (vm.params.per_page) {
                    vm.perPageFilter = vm.params.per_page;
                }

                if (vm.params.filter_title) {
                    vm.filterTitle = vm.params.filter_title;
                }

                if (vm.params.page) {
                    vm.page = vm.params.page;
                }
            }

            document.getElementById('playbook_index').onclick = function () {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }
        },

        methods: {
            ...mapActions({
                getPlaybooks: 'playbookModule/getPlaybooks',
                duplicatePlaybook: 'playbookModule/duplicatePlaybook',
                deletePlaybook: 'playbookModule/deletePlaybook',
                pinUnpinPlaybook: 'playbookModule/pinUnpinPlaybook',
                enableDisableAllClients: 'playbookModule/enableDisableAllClients',
                getPlaybookDetails: 'playbookModule/getPlaybookDetails',
                enableDisableTease: 'teaseModule/enableDisableTease',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
                setIsAssetFolder: 'folderModule/SET_IS_ASSET_FOLDER_STATUS',
                setFolderBreadcrumb: 'folderModule/SET_FOLDER_BREADCRUMB',
                setDashboardParams: 'playbookModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'playbookModule/RESET_DASHBOARD_PARAMS',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;
                const filter = vm.$refs['playbook-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: page });
                vm.page       = page;
                vm.selectedPlaybook = [];
                vm.getPlaybooks(vm.params);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['playbook-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },

            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle     = filter.title;

                vm.setDashboardParams({ key: 'filter_title', value: filter.title });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.selectedPlaybook = [];

                vm.getPlaybooks(vm.params);
            },

            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            refreshPlaybook () {
                const vm = this;

                vm.search           = '';
                vm.selectedPlaybook = [];

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderParams.parent_id ? vm.folderParams.parent_id : null });
                vm.getPlaybooks(vm.params);
            },

            handleDuplicatePlaybook (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate ${ id.length > 1 ? 'these playbooks' : 'this playbook'}`);
                          options.preConfirm = function () {
                                                    return vm.duplicatePlaybook({ id }).then((result) => {
                                                        vm.search           = '';
                                                        vm.selectedPlaybook = [];

                                                        vm.setDashboardParams({ key: 'search', value: '' });
                                                        vm.getPlaybooks(vm.params);
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one playbook to duplicate!');
                }
            },

            handleDeletePlaybook (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ id.length > 1 ? 'these playbooks' : 'this playbook'}`, 'Delete');
                          options.preConfirm = function () {
                                                    return vm.deletePlaybook({ id }).then((result) => {
                                                        if (result) {
                                                            vm.refreshPlaybook();
                                                        }
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one playbook to delete!');
                }
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshPlaybook();
            },

            handlePin (event, playbook) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${playbook.is_pinned ? 'unpin' : 'pin'} this playbook ${playbook.is_pinned ? 'from' : 'in'} your client portal?`);
                      options.preConfirm = function () {
                                                return vm.pinUnpinPlaybook({ id: playbook.id, is_pinned: playbook.is_pinned ? 0 : 1 }).then((result) => {
                                                    if (result) {
                                                        vm.refreshPlaybook();
                                                    }
                                                });
                                            };

                Swal.fire(options).then((result) => {
                    if (!result.isConfirmed) {
                        event.preventDefault();
                        event.target.checked = playbook.is_pinned ? true : false;
                    }
                });
            },

            handleToggleAllClients (event, playbook) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${playbook.all_clients ? 'disable' : 'enable'} this playbook for all clients?`);
                      options.preConfirm = function () {
                                                return vm.enableDisableAllClients({ id: playbook.id, all_clients: playbook.all_clients ? 0 : 1 }).then((result) => {
                                                    if (result) {
                                                        vm.refreshPlaybook();
                                                    }
                                                });
                                            };

                Swal.fire(options).then((result) => {
                    if (!result.isConfirmed) {
                        event.preventDefault();
                        event.target.checked = playbook.all_clients ? true : false;
                    }
                });
            },

            handleSharePlaybook (playbook) {
                const vm = this;

                if (playbook.share_count == 0) {
                    vm.sharePlaybook = true;
                    vm.activePlaybook = playbook;
                } else if (playbook.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            updateFolderBreadcrumb (breadcrumb) {
                const vm = this;

                vm.setFolderBreadcrumb({ key: 'playbook', value: breadcrumb ? breadcrumb : [] });
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },

            createNew (el) {
                const vm = this;

                if(el === 'classic'){
                    vm.newPlaybook = true;
                } else{
                    vm.canvaWidget = true;
                }

                vm.playbookType = false;
            },

            handleRefreshPlaybook (id) {
                const vm = this;

                vm.getPlaybookDetails(id).then((playbook) => {
                    vm.activePlaybook = playbook;
                });
            },

            handleTeaseConfirmation (event, playbook) {
                const vm = this;

                if (event.target.type == 'checkbox') {
                    if (event.target.checked) {
                        vm.teaseConfirmation = true;
                        vm.activePlaybook = playbook;
                    } else {
                        vm.teaseConfirmation = false;

                        vm.enableDisableTease({ id: playbook.tease_setting.id, enabled: 0 });
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list>li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: -30px;
        top: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        transition: all 0.3s ease-in-out;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }
    .plybook_item .switch_list{
        position: absolute;
        right: -125px;
        bottom: 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: all 0.3s ease-in-out;
    }
    .plybook_item label.switch_option h5{
        font-size: 13px;
        color: #2c3e50;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .plybook_item label.capsule_btn div{
        transform: scale(0.7);
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }
    .plybook_item .item_img:hover .replace_img, .plybook_item .item_img:hover .switch_list{
        right: 15px;
    }

    @keyframes dropMove2 {
        from {
            margin-bottom: 10px;
            opacity: 0;
        }

        to {
            margin-bottom: 1px;
            opacity: 1;
        }
    }

    :deep(.share_playbook .modal_container) {
        height: 640px;
    }

    :deep(.share_playbook .modal_container .modal_header) {
        padding: 20px 30px 0;
    }

    :deep(.share_playbook .modal_container .modal_body) {
        padding: 0 30px;
        flex-direction: column;
    }
    :deep(.share_playbook .modal_container .modal_footer) {
        border-radius: 0 0 12px 12px;
    }

    :deep(.share_playbook .tab_row) {
        padding: 20px 0 0 0;
        display: flex;
    }

    :deep(.share_playbook .tab_row li) {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    :deep(.share_playbook .tab_row li.active) {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    :deep(.share_playbook .tab_wpr) {
        width: 100%;
    }

    :deep(.share_playbook .result_wpr) {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 16.666%;
    }

    .analytics_card {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card h4 {
        font-size: 15px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    :deep(.action_list) {
        display: flex;
        flex-wrap: wrap;
    }

    :deep(.action_list li) {
        margin-right: 5px;
        cursor: pointer;
    }

    :deep(.action_list li i.fa-envelope) {
        color: #f2a31d;
    }

    :deep(.action_list li i.fa-reply) {
        color: #2f7eed;
    }

    :deep(.action_list li i.fa-trash-alt) {
        color: #eb1414;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
        display: flex;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
        margin-right: 5px;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    .add_types ul{
        width: 100%;
    }
    .add_types ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .add_types ul li {
        padding: 10px;
        width: 50%;
    }
    .add_types ul li .card_item{
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .add_types ul li .card_item img{
        margin-bottom: 25px;
    }
    .add_types .close_btn  {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    @media(max-width: 768px){
        .add_types ul li .card_item{
            font-size: 11px;
            line-height: 14px;
        }
        .add_types ul li .card_item img{
            max-height: 22px;
            margin-bottom: 20px;
        }
    }
    /* @media(max-width: 600px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */

    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
